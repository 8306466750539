<template>
    <div>
    </div>
</template>

<script>
import Vue from 'vue'
import axios from 'axios'

export default {
    name: 'lading-page',
    mounted() {
        this.init()
    },
    methods: {
        init: async function() {
            // the landing page should only be visited once
            // IF everything goes as planned
            this.$root.$data.landed = true
            this.$root.$data.loading = true

            // param for returning after a successfull
            this.$root.$data.successurl = this.$route.query.successurl

            // aboonline flow, existing user with TO KVP, FKI etc.
            const passedtoken   = this.$route.query.token
            // app flow, existing user without TO contract (and TO KVP/FKI etc.)
            const registertoken = this.$route.query.register

            const bcode = this.$route.query.Bcode || this.$route.query.bcode || this.$route.query.staff
            const lbvid = this.$route.query.LBVID || this.$route.query.lbvid || this.$route.query.department

            // apply mobile (dticket) style if needed
            // check param independent of its value
            const needsMobileTheme = !!this.$route.query.mobile || this.$route.query.mobile === null

            if (needsMobileTheme) {
                this.applyMobileStyle()
            }

            try {
                await this.initBaseData()

                if (passedtoken) {
                    await this.prepareExistingUser(passedtoken)
                } else if (registertoken) {
                    await this.prepareAppUserRegistration(registertoken)
                } else if (lbvid || bcode) {
                    this.prefillUserdataFromQuery(lbvid, bcode)
                }

                this.$root.$data.loading = false
                this.$router.push({ name: 'product-selection' })
            } catch(err) {
                console.log(err)
                // in case of an error, reset the landed status
                this.$root.$data.landed = false
            }
        },
        applyMobileStyle() {
            // '$route.query' evaluates unset params to undefined and empty ones to null
            // unlike the 'window.location' which evaluates unset params to null and empty ones to ''
            const basePath    = process.env.NODE_ENV === 'production' ? '/anlageassistent-swms' : '/'
            const resourceUrl = (basePath + '/css/dticket.css').replace('//', '/')
            const themeEl     = document.createElement('link')

            themeEl.setAttribute('rel', 'stylesheet')
            themeEl.setAttribute('href', resourceUrl)

            document.querySelector('head').append(themeEl)
        },
        initBaseData: async function() {
            var url = Vue.middleware()
            url += '/basedata/salutations'

            return axios.get(url)
            .then(result => {
                this.$root.$data.basedata.salutations = result.data.data.salutationlist.map(entry => {
                    return {
                        displayid: entry.IDAnrede,
                        displayname: entry.AnredeText
                    }
                })

                var titlesurl = Vue.middleware()
                titlesurl += '/basedata/titles'

                return axios.get(titlesurl)
            })
            .then(result => {
                this.$root.$data.basedata.titles = result.data.data.titlelist.map(entry => {
                    return {
                        displayid: entry.IDTitel,
                        displayname: entry.TitelText
                    }
                })

                var countriesurl = Vue.middleware()
                countriesurl += '/basedata/countries'

                return axios.get(countriesurl)
            })
            .then(result => {
                this.$root.$data.basedata.countries = result.data.data
            })
        },
        prefillUserdataFromQuery(lbvid, bcode) {
            const city      = this.$route.query.Ort       || this.$route.query.ort       || this.$route.query.city
            const housenr   = this.$route.query.HausNr    || this.$route.query.hausnr    || this.$route.query.housenr
            const street    = this.$route.query.Strasse   || this.$route.query.strasse   || this.$route.query.street
            const firstname = this.$route.query.Vorname   || this.$route.query.vorname   || this.$route.query.firstname
            const name      = this.$route.query.Name      || this.$route.query.name      || this.$route.query.lastname
            const zip       = this.$route.query.PLZ       || this.$route.query.plz       || this.$route.query.zip

            const customer = {
                email: '',
                confirmemail: '',
                createaccount: false,
                salutation: { displayid: -1, displayname: ''},
                title: { displayid: -1, displayname: ''},
                firstname: firstname,
                lastname: name,
                birthdate: '',
                phone: '',
                street: street,
                housenr: housenr,
                district: '',
                zip: zip,
                city: city,
                country: { displayid: -1, displayname: ''},
                addressinfo: '',
                differentcardowner: false,
                iban: '',
                bic: '',
                differentbankaccountowner: false,
                sepaconfirmed: false,
                field1: '',
                field2: '',
                field3: '',
                voucher: '',
                // ONLY AVAILABLE IF PASSED AS QUERY PARAM
                lbvid,
                bcode
            }
            const customerHasData = {
                firstname:   !!customer.firstname,
                lastname:    !!customer.lastname,
                // Diese lassen wir absichtlich weg - da bei den Daten von LBV oft die Hausnummer mit in der Straße übertragen wird, und manuell angepasst werden muss 
                //street:      !!customer.street,
                //housenr:     !!customer.housenr,
                zip:         !!customer.zip,
                city:        !!customer.city,
            }

            this.$root.$data.customerHasData = customerHasData
            this.$root.$data.customer = customer
        },
        prepareAppUserRegistration: async function(registertoken) {

            // prefill user from token
            let url = Vue.middleware()
            url += '/userprofile/users/' + registertoken

            const response = await axios.get(url)
            const userdata = response.data

            if (!userdata) {
                return false
                // TODO: what should happen?
            }

            this.$root.$data.isexistingcustomer = true

            const salutation = this.$root.$data.basedata.salutations.find(salutation => salutation.displayname === userdata.salutation)
            const title      = this.$root.$data.basedata.titles.find(title => title.displayname === userdata.title)
            const country    = this.$root.$data.basedata.countries.find(country => country.original === userdata.country)

            const customer = {
                email: userdata.email || '',
                confirmemail: '',
                createaccount: false,
                salutation: salutation || { displayid: -1, displayname: ''},
                title: title || { displayid: -1, displayname: ''},
                firstname: userdata.firstname || '',
                lastname: userdata.lastname || '',
                birthdate: this.formatNpsDate(userdata.birthdate || ''),
                phone: userdata.phone || '',
                street: userdata.street || '',
                housenr: userdata.housenr || '',
                district: '',
                zip: userdata.zip || '',
                city: userdata.city || '',
                country: country || { displayid: -1, displayname: ''},
                addressinfo: userdata.addressinfo || '',
                differentcardowner: false,
                iban: '',
                bic: '',
                differentbankaccountowner: false,
                sepaconfirmed: false,
                field1: '',
                field2: '',
                field3: '',
                voucher: '',
                nutzerIdToken : userdata.nutzerIdToken,
                // ONLY AVAILABLE IF PASSED AS QUERY PARAM
                registertoken
            }

            // save if there was customer data to disable inputs with known values
            const customerHasData = {
                email:       !!customer.email,
                salutation:  customer.salutation.displayid !== -1,
                title:       customer.title.displayid !== -1,
                firstname:   !!customer.firstname,
                lastname:    !!customer.lastname,
                birthdate:   !!customer.birthdate,
                phone:       !!customer.phone,
                street:      !!customer.street,
                housenr:     !!customer.housenr,
                addressinfo: !!customer.addressinfo,
                zip:         !!customer.zip,
                city:        !!customer.city,
                country:     customer.country.displayid !== -1,
            }

            this.$root.$data.customer = customer
            this.$root.$data.customerHasData = customerHasData
        },
        formatNpsDate(date) {
            if (!date) {
                return ''
            }

            const parts = date.split('.')

            if (!parts.length === 3) return ''

            return `${parts[1]}.${parts[0]}.${parts[2]}`
        },
        getuserdata: async function(token) {
            if (!token) {
                return false
            }

            this.$root.$data.loading = true

            let usertokenurl = Vue.middleware()
            usertokenurl += '/userprofile/usertoken/' + encodeURIComponent(token.replaceAll('+', '-').replaceAll('/', '_'))

            return axios.get(usertokenurl)
            .then(response => {

                if(response.data.tokenExists === true) {

                    //if customer exists in nps
                    this.$root.$data.isexistingcustomer = true

                    const customer = {
                        email: response.data.token.nutzerEmailAdresse,
                        confirmemail: response.data.token.nutzerEmailAdresse,
                        createaccount: false,
                        salutation: '',
                        title: '',
                        firstname: '',
                        lastname: '',
                        birthdate: '',
                        phone: '',
                        street: '',
                        housenr: '',
                        district: '',
                        zip: '',
                        city: '',
                        country: '',
                        addressinfo: '',
                        differentcardowner: false,
                        iban: '',
                        bic: '',
                        differentbankaccountowner: false,
                        sepaconfirmed: false,
                        field1: '',
                        field2: '',
                        field3: '',
                        voucher: '',
                        nutzerIdToken : response.data.token.nutzerIdToken
                    }

                    this.$root.$data.customer = customer

                    //search customer in TO
                    let customerurl = Vue.middleware()
                    customerurl += '/customer/' + encodeURIComponent(token)

                    return axios.get(customerurl)
                    .then(response => {
                        const status = response.data.status
                        const data   = response.data.data

                        if (status.ErrorCode !== 0) {
                            return false
                        } else {
                            return data
                        }

                    })
                } else {
                    return false
                }
            })
        },
        prepareExistingUser: async function(token) {
            const userdata = await this.getuserdata(token)

            if(this.$root.$data.isexistingcustomer && !userdata) {
                return false
            } else if (!userdata) {
                this.$root.$data.isexistingcustomer = false
                return false
            }

            // save that we are using an existing customers data
            this.$root.$data.isexistingcustomer = true

            const kvpdata = userdata.KVP.Personendaten
            const fkidata = userdata.FKI.Personendaten
            const kontodata = userdata.KontoInhaber.Personendaten

            const customer = {
                email: kvpdata.Email,
                confirmemail: kvpdata.Email,
                createaccount: false,
                salutation: this.$root.$data.basedata.salutations.find(salutation => salutation.displayid === kvpdata.IDAnrede) || { displayid: -1, displayname: ''},
                title: this.$root.$data.basedata.titles.find(title => title.displayid === kvpdata.IDTitel) || { displayid: -1, displayname: ''},
                firstname: kvpdata.Vorname,
                lastname: kvpdata.Nachname,
                birthdate: this._formatDate(kvpdata.GebDatumDate),
                phone: kvpdata.Telefon,
                street: kvpdata.Strasse,
                housenr: kvpdata.HausNr,
                district: kvpdata.Ortsteil,
                zip: kvpdata.PLZ,
                city: kvpdata.Ort,
                country: this.$root.$data.basedata.countries.find(country => country.original === kvpdata.Land) || { displayid: -1, displayname: ''},
                addressinfo: kvpdata.HausNrZusatz,
                differentcardowner: !userdata.FKI.IdentischMitKunde,
                iban: userdata.KontoInhaber.IBAN,
                bic: userdata.KontoInhaber.BIC,
                differentbankaccountowner: !userdata.KVPIstIdentischMitKtoInh,
                sepaconfirmed: false,
                field1: '',
                field2: '',
                field3: '',
                voucher: '',
                nutzerIdToken : token
            }

            this.$root.$data.customer = customer

            if (customer.differentcardowner) {
                const cardowner = {
                    salutation: this.$root.$data.basedata.salutations.find(salutation => salutation.displayid === fkidata.IDAnrede) || { displayid: -1, displayname: ''},
                    title: this.$root.$data.basedata.titles.find(title => title.displayid === fkidata.IDTitel) || { displayid: -1, displayname: ''},
                    firstname: fkidata.Vorname,
                    lastname: fkidata.Nachname,
                    birthdate: this._formatDate(fkidata.GebDatumDate),
                    phone: fkidata.Telefon,
                    street: fkidata.Strasse,
                    housenr: fkidata.HausNr,
                    district: fkidata.Ortsteil,
                    zip: fkidata.PLZ,
                    city: fkidata.Ort,
                    addressinfo: fkidata.HausNrZusatz,
                    country: this.$root.$data.basedata.countries.find(country => country.original === fkidata.Land) || { displayid: -1, displayname: ''},
                }
                this.$root.$data.cardowner = cardowner
            }

            if (customer.differentbankaccountowner) {
                const bankaccountowner = {
                    salutation: this.$root.$data.basedata.salutations.find(salutation => salutation.displayid === kontodata.IDAnrede) || { displayid: -1, displayname: ''},
                    title: this.$root.$data.basedata.titles.find(title => title.displayid === kontodata.IDTitel) || { displayid: -1, displayname: ''},
                    firstname: kontodata.Vorname,
                    lastname: kontodata.Nachname,
                    birthdate: this._formatDate(kontodata.GebDatumDate),
                    phone: kontodata.Telefon,
                    street: kontodata.Strasse,
                    housenr: kontodata.HausNr,
                    district: kontodata.Ort,
                    zip: kontodata.PLZ,
                    city: kontodata.Ort,
                    addressinfo: kontodata.HausNrZusatz,
                    country: this.$root.$data.basedata.countries.find(country => country.original === kontodata.Land) || { displayid: -1, displayname: ''},
                }
                this.$root.$data.bankaccountowner = bankaccountowner
            }

            return true
        }
    }
}
</script>

<style scoped>

</style>