<template>
    <div class="accordion-container round-container" :id="uniqueid">
        <div class="accordion round-container" ref="header" @click="headerClick" :class="[ isopen?'open':'', active?'active':'']">
            <label>{{ title }}</label>
            <slot name="header"></slot>
            <div class="icon">
                <slot name="icon"></slot>
                <v-icon v-if="active && !isopen" name="plus-circle" />
            </div>
        </div>
        <div class="panel" :class="[{ 'open' : (isopen && active) }, applyoverflow?'overflow':'']" :ref="uniqueid" :style="{ 'max-height' : maxheight + 'px' }">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import 'vue-awesome/icons/plus-circle'
import 'vue-awesome/icons/minus-circle'

export default {
    props: {
        title: String,
        open: {
            type: Boolean,
            default: false
        },
        active: {
            type: Boolean,
            default: true
        },
        toggleable: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            isopen: false,
            uniqueid: Math.random().toString(36).substr(2, 9),
            maxheight: 0,
            applyoverflow: false
        }
    },
    mounted() {
        if (this.open) this.toggle()
    },
    watch: {
        isopen(newvalue) {
            this.$emit('update:open', newvalue)
        }
    },
    methods: {
        headerClick() {
            if (this.active && this.toggleable) {
                this.toggle()
                this.$emit('click')
            }
        },
        toggle() {
            let timeout = 0
            const element = this.$refs[this.uniqueid]

            this.isopen = !this.isopen

            if (this.isopen) {
                this.maxheight = element.scrollHeight
                timeout = this._getTransitionDuration(element) + 1
            } else {
                this.maxheight = 0
            }

            // if we open the panel, we wait for the transition (200ms) to end,
            // so we dont get a weird overflow, we need an extra property for this
            // because the panel must be open and the maxheight already set for this to work
            setTimeout(() => {
                this.applyoverflow = this.isopen
                const element = document.getElementById(this.uniqueid)
                if (this.isopen && element) element.scrollIntoView({behavior: 'smooth'})
            }, timeout)
        }
    }
}
</script>

<style>
.accordion-container {
    box-shadow: 0px 2px 5px rgb(100 100 150 / 30%);
}

.accordion {
    display: flex;
    justify-content: space-between;
    background-color: #fff;
    color: #064A6C;
    padding: 20px;
    width: 100%;
    text-align: left;
    border: none;
    outline: none;
    transition: 0.2s;
    font-size: 1.5em;
    font-weight: bold;
    user-select: none;
}

.accordion:not(.active) {
    background-color: #ccc;
    color: #999;
    cursor: unset;
}

.accordion.open {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.accordion.active:not(.open):hover {
    background-color: #ccc;
}

.accordion.active:not(.open):hover,
.accordion.active:not(.open):hover > * {
    cursor: pointer;
}

.accordion > .icon {
    fill: #064A6C;
}

.panel {
    padding: 0 30px;
    background-color: #fff;
    max-height: 0;
    overflow: hidden;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    /* 
        no shorthand for the transition,
        so we can easily obtain the duration via js
    */
    transition-property: max-height;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
}

.panel.open.overflow {
    overflow: visible;
}
</style>